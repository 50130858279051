import React from 'react'
import Headroom from 'react-headroom'
import './header.scss'
import Logo from './logo'
import MainNavigation from './mainNavigation'

const Header = () => (
  <Headroom>
    <header className="main-header">
      <Logo />
      <MainNavigation />
    </header>
  </Headroom>
)

export default Header
