import React from 'react'
import { Link } from 'gatsby'
import './mainNavigation.scss'

const MainNavigation = () => {
    return(
        <nav className="main-navigation">
            <ul>
                <li><Link to="/quem-somos">Quem Somos</Link></li>
                <li><Link to="/oque-fazemos">O que fazemos</Link></li>
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/fale-conosco">Fale Conosco</Link></li>
                <li><Link to="/agende" className="link-special">Agende uma sessão</Link></li>
            </ul>

            <ul>
                <li><a href="https://www.facebook.com/gruporeinserir" target="_blank" rel="noreferrer" className="social social--facebook">Facebook</a></li>
                <li><a href="https://www.instagram.com/gruporeinserir/" target="_blank" rel="noreferrer" className="social social--instagram">Instagram</a></li>
            </ul>
        </nav>
    )
}

export default MainNavigation
