import React from 'react'
import { Link } from 'gatsby'

import './main-footer.scss'

import Logo from './reinserir-completo.svg'
import Yupper from './yupper.svg'

const Footer = () => {
    return(
        <footer className="main-footer">
            <div className="main-footer__container">
                <div className="main-footer__column">
                    <img src={ Logo } alt="Grupo Reinserir" />
                </div>

                <div className="main-footer__column">
                    <div className="main-footer__columns">
                        <div className="main-footer__address">
                            <p>Rua Barão de Itapetininga, 273, 9º andar, sala C. — República</p>
                            <p>CEP 01042-913 — São Paulo, SP — <a href="https://goo.gl/maps/nY6ZPo9XxUDPBs8G6" target="_blank" rel="noreferrer">Como chegar</a></p>
                        </div>

                        <div className="main-footer__social-icons">
                            <a href="https://www.instagram.com/gruporeinserir/" rel="noreferrer" target="_blank" className="link link--instagram">Instagram</a>
                            <a href="https://www.facebook.com/gruporeinserir" target="_blank" rel="noreferrer" className="link link--facebook">Facebook</a>
                        </div>
                    </div>

                    <div className="main-footer__columns">
                        <nav>
                            <h4>Nos conheça</h4>
                            <ul>
                                <li><Link to="/oque-fazemos">Conheça nossos serviços</Link></li>
                                <li><Link to="/quem-somos#nosso-espaco">Conheça nosso espaço</Link></li>
                                <li><Link to="/quem-somos#nossa-equipe">Conheça nossa equipe</Link></li>
                                <li><Link to="/perguntas-frequentes">Perguntas frequentes</Link></li>
                            </ul>
                        </nav>

                        <nav>
                            <h4>Contato</h4>
                            <ul>
                                <li><Link to="/agende">Agende uma sessão</Link></li>
                                <li><Link to="/cadastro">Cadastro de paciente</Link></li>
                                <li><Link to="/fale-conosco">Fale conosco</Link></li>
                                <li><Link to="/trabalhe-conosco">Trabalhe conosco</Link></li>
                            </ul>
                        </nav>

                        <nav>
                            <h4>Mais da Reinserir</h4>
                            <ul>
                                <li><Link to="/blog">Nosso Blog</Link></li>
                                <li><a href="https://www.facebook.com/gruporeinserir" target="_blank" rel="noreferrer">Facebook</a></li>
                                <li><a href="https://www.instagram.com/gruporeinserir/" target="_blank" rel="noreferrer">Instagram</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="main-footer__copy">
                <p>© Grupo Reinserir 2020 - Todos os diretos reservados</p>
                <div>
                    <p>Webdesign by</p>
                    <p><a href="http://yupper.digital" target="_blank" rel="noreferrer"><img src={ Yupper } alt="Yupper" /></a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
