import React from "react"
import PropTypes from "prop-types"
import { useLocation } from '@reach/router'
import "typeface-manrope"
import "typeface-noto-serif"
import "./../styles/style.scss"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const { pathname } = useLocation();


  let pageClass
  if (pathname === '/') {
    pageClass = 'home'
  } else {
    pageClass = pathname.slice(1)
  }

  return (
    <div className={ `pageClass pageClass--${pageClass}` }>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
