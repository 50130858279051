import React from 'react'
import { Link } from 'gatsby'
import logo from './reinserir.svg'

const Logo = () => {
    return(
        <div className="main-header__logo">
            <Link to="/">
                <img src={ logo } alt="Grupo Reinseirir" />
            </Link>
        </div>
    )
}

export default Logo
